import { createGlobalStyle } from 'styled-components';

import theme from './theme';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${theme.colors.primary};
  }

  a {
    color: white;
    transition: all ${theme.transitions.quick} ease;
  }
`;

export default GlobalStyle;
