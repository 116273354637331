const colors = {
  primary: '#161618',
  white: 'white',
  grey: {
    dark: 'rgba(0,0,0,0.9)',
    default: 'rgba(0,0,0,0.7)',
    light: 'rgba(0,0,0,0.5)',
    lighter: 'rgb(222,222,222)',
    darker: 'rgba(255,255,255,0.5)',
  },
};

const button = {
  colors: {
    primary: {
      background: colors.white,
      backgroundHover: 'rgba(255,255,255,0.9)',
      color: colors.primary,
    },
  },
  radius: {
    small: '3px',
    large: '6px',
    round: '100%',
  },
};

const theme = {
  colors,
  button,
  transitions: {
    normal: '0.5s',
  },
  screen: {
    xs: '575px',
    sm: '767px',
    md: '991px',
    lg: '1199px',
  },
};

export default theme;
