import React from 'react';
import styled, { css } from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Fade from 'react-reveal/Fade';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Container, Row, Section } from '../components/Grid';
import Header from '../components/header';

const TextColumn = styled.div`
  max-width: 30rem;
  margin-bottom: 2rem;
`;

const Heading = styled.h1`
  font-size: 50px;
  line-height: 70px;
  margin-bottom: 3rem;
`;

const Content = styled.p`
  color: #636363;
  font-size: 28px;
  line-height: 40px;
  margin-bottom: 6vw;
`;

const AboutWrapper = styled.div`
  footer {
    display: none;
  }
`;

const AboutUs = () => {
  return (
    <div style={{ backgroundColor: 'black' }}>
      <AboutWrapper>
        <Layout>
          <SEO title="About us" />
          <div>
            <Header close />
            <Container
              css={css`
                padding-top: 20vw;
              `}
            >
              <Row>
                <Section>
                  <TextColumn>
                    <Fade bottom delay={500} exit={false}>
                      <Heading>About us</Heading>
                    </Fade>
                    <Fade bottom delay={500} exit={false}>
                      <Content>
                        We are a group of investors, brokers, real estate agents
                        that came together to fix the supply problem most
                        investors have. Our strategy is simple and common, you
                        make your money on the buy. So, we work hard to source
                        and negotiate the best deal and purchase them for
                        ourselves and our clients.
                      </Content>
                    </Fade>

                    <Fade bottom delay={500} exit={false}>
                      <Content>
                        We realised quickly that we could find great under value
                        property faster than we could buy them, so we began
                        giving other people these opportunities. And so
                        buyersclub.co.nz was born.
                      </Content>
                    </Fade>

                    <Fade bottom delay={500} exit={false}>
                      <Content>
                        The idea is simple, attach your name to a database and
                        be fed leads. But we wanted to take it one step further
                        by creating a personal touch. When you become a
                        BuyersClub investor, you will receive a phone call
                        directly with a lead and if you’re genuinely interested,
                        then that’s followed up with our value pack, which is
                        our property specific information pack on the potential
                        of your new purchase.
                      </Content>
                      <Content>
                        Today, Buyersclub.co.nz is feeding investors every month
                        with quality property both on and off the market, to
                        help normal New Zealanders to become property
                        millionaires.
                      </Content>
                    </Fade>
                  </TextColumn>
                </Section>
              </Row>
            </Container>
          </div>
        </Layout>
      </AboutWrapper>
    </div>
  );
};

export default AboutUs;
