import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 4rem 16px 0;
  z-index: 10;
`;

const HeaderContainer = styled.div`
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
`;

const LinkHolder = styled.div`
  display: flex;
  justify-content: space-between;

  a {
    text-decoration: none;
    border-bottom: 1px solid;
    opacity: 0.8;
    transition: opacity 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &:hover {
      text-decoration: none;
      opacity: 1;
    }
  }
`;

const Header = ({ close }) => {
  return (
    <StyledHeader>
      <HeaderContainer>
        <LinkHolder>
          {close ? (
            <AniLink fade to="/">
              Close
            </AniLink>
          ) : (
            <>
              <AniLink swipe direction="right" top="entry" to="about-us">
                About us
              </AniLink>
              <Link to="#join">Join the club</Link>
            </>
          )}
        </LinkHolder>
      </HeaderContainer>
    </StyledHeader>
  );
};

Header.defaultProps = {
  close: false,
};

Header.propTypes = {
  close: PropTypes.bool,
};

export default Header;
