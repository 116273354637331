/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import Container from './Grid/container';

const Disclaimer = styled.p`
  font-size: 13px;
  max-width: 32rem;
  margin: 0 auto;
  margin-bottom: 80px;
  text-align: center;
`;

const Copyright = styled(Disclaimer)`
  margin-bottom: 28px;
`;

const Footer = props => (
  <footer>
    <Container>
      <Disclaimer>
        All the information on this website is published in good faith and for
        general information purpose only. Buyersclub does not make any
        warranties about the completeness, reliability and accuracy of this
        information. Any action you take upon the information you find on this
        website (buyersclub.co.nz), is strictly at your own risk. will not be
        liable for any losses and/or damages in connection with the use of our
        website.
      </Disclaimer>
      <Copyright>
        © {new Date().getFullYear()} {props.data.site.siteMetadata.title}
      </Copyright>
    </Container>
  </footer>
);

// eslint-disable-next-line no-unused-vars
export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            contact {
              phone
              email
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} />}
  />
);
